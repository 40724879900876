import { AUTH_PROVIDERS } from 'constants/auth';
import { BUSINESS_PRODUCT_TYPES } from 'constants/index';
import { PermissionType } from 'types/access';
import { CustomPaymentLinkPayload } from 'types/paymentRequests';
import { Account, Entity } from 'types/romaAccounts';
import { BulkPayout, TransactionType } from 'types/transactions';

export interface ApiErrorResponse {
  data: {
    error: {
      code: string;
      message: string;
    };
  };
}

export interface SignUpBodyType {
  email: string;
  first_name: string;
  last_name: string;
  merchant_name?: string;
  mobile_number: string;
  capability_intent: Array<string>;
  entity_details: Array<{ name: string; geography: string }>;
  referral_code: string | string[] | undefined;
  account_type: string;
}

export interface SignInBodyType {
  email: string;
}

export interface ValidateSignUpBodyType {
  token: string;
  first_name: string;
  last_name: string;
  merchant_name?: string;
  mobile_number: string;
  otp: string;
  capability_intent: Array<string>;
  entity_details: Array<{ name: string; geography: string }>;
  referral_code: string | string[] | undefined;
  account_type: string;
}

export interface ValidateSignInBodyType {
  token: string;
  otp: string;
}

export interface ResendOtpBodyType {
  token: string;
}

export interface SignUpResponseType {
  token: string;
}

export interface SignInResponseType {
  token: string;
  context?: {
    clientId: string;
    provider: AUTH_PROVIDERS;
    issuer: string;
    type: string;
    product: BUSINESS_PRODUCT_TYPES;
  };
}
export interface ValidateSignInResponseType {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
  updated_at: string;
  is_cash_ops_enabled: boolean;
}

export interface ValidateSignInV2BodyType {
  token: string;
  code: string;
}

export enum UserAndMerchantStatusType {
  ACTIVE = 'active',
  TNC_PENDING = 'tnc_pending',
  INACTIVE = 'in-active',
}

export interface GetUserResponseType {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  created_at: string;
  updated_at: string;
  merchant_id: string;
  is_mfa_enabled: boolean;
  fullName: string;
  status: UserAndMerchantStatusType;
}
export interface ResendOtpResponseType {
  data: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignedUrlBodyType {}

export interface KybDetail {
  key: string;
  value: string;
  parent: string;
}

export interface KybSubmitPayload {
  data: {
    region: string;
    kyb_details: KybDetail[];
    is_tnc_accepted: boolean;
    tnc_accepted_at: number;
    tnc_viewed_at: number;
    tnc_service_code: string;
  };
  idempotencyHeader?: string;
}

export interface KybSubmitPayloadWithId {
  data: {
    region: string;
    kyb_details: KybDetail[];
    is_tnc_accepted: boolean;
    tnc_accepted_at: number;
    tnc_viewed_at: number;
    tnc_service_code: string;
  };
  entityId: string;
  idempotencyHeader?: string;
}

export interface KybSubmitResponse {
  kyb_id: string;
  entity_id: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SignedUrlResponseType {
  data: {
    data: string;
  };
}

export interface UploadFilePayload {
  url: string;
  file: File;
}

export interface CreateBeneficiaryPayloadType {
  beneficiary_name: string;
  beneficiary_account_number: string;
  payout_method: string;
}

export interface CreateOrderPayloadType {
  catalog_id: string;
  amount: number;
  currency: string;
  tenure_in_days: number;
}

export interface CreateOrderResponseType {
  id: string;
}

export interface GetInvestmentSummaryResponseType {
  total_interest_earned: number;
  total_invested: number;
  total_value: number;
  total_buying_power: number;
  total_security_balance: number;
  cash_account_id?: string;
}

export interface AccountDetails {
  bank_name: string;
  routing_number: string;
  account_holder: string;
  account_number: string;
  memorandum: string;
  bank_address: string;
  beneficiary_address: string;
}

export interface InitiateTOTPResponseType {
  totp_secret: string;
  qr_code: string;
  token: string;
}

export interface ValidateTOTPRequestType {
  totp: string;
  email_otp: string;
  token: string;
}

export interface BulkPayoutResponse {
  id: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export interface InitiateBulkPayoutPayload {
  key: string;
}

export interface GetPayoutsByBulkIdResponse extends BulkPayoutResponse {
  total_amount: number;
  total_fees: number;
  total_beneficiaries: number;
  payouts: BulkPayout[];
  source_currency_code: string;
  error: string;
  payout_count: number;
  file?: string;
  network: string;
  error_rows: number[];
  columns: string[];
  account_id?: string;
  sum_amount?: number;
}

export interface BulkPayoutIdPayload {
  id: string;
}

export interface ExceuteBulkPayoutPayload {
  data: BulkPayoutIdPayload;
  headerKey: string;
}

export interface GetListOfBulkPayoutsPayload {
  page?: number;
  limit?: number;
  status?: string;
}

export interface B2bUserConfigurtionType {
  b2b_user_txn_incoming_notif_mail: boolean;
  b2b_user_txn_outgoing_notif_mail: boolean;
  b2b_user_txn_fail_notif_mail: boolean;
}

export enum B2bUserConfigurtionParams {
  b2b_user_txn_incoming_notif_mail = 'b2b_user_txn_incoming_notif_mail',
  b2b_user_txn_outgoing_notif_mail = 'b2b_user_txn_outgoing_notif_mail',
  b2b_user_txn_fail_notif_mail = 'b2b_user_txn_fail_notif_mail',
}

export interface GetTransactionsByBeneficiaryIdPayload {
  id: string;
  page?: number;
  limit?: number;
}

export interface GetTransactionsByBeneficiaryIdResponse {
  payout_sessions: TransactionType[];
}

export interface CreateSessionPayload {
  capability_context?: string;
  remarks?: string;
}

export interface IdempotencySessionResponse {
  id: string;
  b2b_user_id: string;
  remarks: string;
  capability_context: string;
  created_at: string;
  updated_at: string;
  status: string;
}
export interface GetCurrencyConfigsforBeneficiaryPayload {
  beneficiary_id: string;
}

export interface PayoutConfigsForThirdPartyRequest extends GetCurrencyConfigsforBeneficiaryPayload {
  vault_id?: string;
  account_id?: string;
  version: string;
}
export interface GetAccountBalanceForAccountIdRequest {
  account_id: string;
}

export interface GetAccountBalanceForAccountIdResponse {
  balance: string;
  account_id: string;
}

export interface CustomPaymentLinkPayloadWithIdempotency {
  data: CustomPaymentLinkPayload;
  idempotencyHeader: string;
}

export interface CreatePayableAccountPayloadTypes {
  account_holder_name: string;
  account_number: number;
  routing_code: string;
  swift_code: string;
  phone_number: number;
  tin: string;
  headerKey: string;
}

export interface LinkedAccountDetails {
  id: string;
  account_status: string;
  bank_name: string;
  account_holder_name: string;
  depository_type: string;
  account_number: number;
  routing_code: number;
  institution_name: string;
  officical_name: string;
  iban: string;
  bic: string;
  created_at: Date;
  updated_at: Date;
  status: '';
  swift_code: number;
  phone_number: number;
  tin: number;
}

export interface LinkNewAccountPayload {
  details: Record<string, string | number>;
  totp: string;
  idempotencyHeader: string;
}
export interface WithdrawFunsPayloadType {
  payload: Record<string, string | number>;
  idempotencyHeader: string;
  headerKey: string;
}

export interface ContactUsPayloadTypes {
  type: string;
  text: string;
}

export interface ReportsResponseTypes {
  id: number;
  document_url: string;
  document_type: string;
  generated_on: string;
  report_name: string;
  created_at: string;
}
export interface IdempotencyHeaderType {
  idempotencyHeader: string;
}

export interface GetVaultsForPayoutsRequestType {
  recipientId: string;
}
export interface VaultResponse {
  id: string;
  merchant_id: string;
  entity_id: string;
  entity_name: string;
  entity_geography: string;
  type: string;
  name: string;
  status: string;
  balance?: string;
  created_at: string;
  updated_at: string;
}

export interface VaultResponseById {
  vault: VaultResponse;
  accounts: Account[];
  add_currency?: boolean;
}

export interface EditVaultPayload extends IdempotencyHeaderType {
  body: {
    data: {
      name: string;
    };
    id: string;
  };
}

export interface AddCurrencyPayload extends IdempotencyHeaderType {
  data: {
    vault_id: string;
    currency: string[];
  };
}

export interface RequestCurrencyPayload extends IdempotencyHeaderType {
  data: {
    currency_code: string;
    entity_name: string;
  };
}

export interface GetValidVaultCurrenciesResponse {
  allowed_currency: Record<string, string>;
  all_currency: Record<string, string>;
}

export interface CreateVaultPayload extends IdempotencyHeaderType {
  data: {
    entity_id: string;
    name: string;
    currency: string[];
  };
}

export interface GetAllEntitiesAndAccountsResponse {
  entities: Entity[];
}

export interface MfaTotpType {
  totp: string;
}

export interface UserPermissionsResponseType {
  permissions: PermissionType[];
  roles: { id: string; name: string }[];
}

export interface ApiAccessHeaderParamType {
  resource_id: string;
}

export interface UpdateSlippagePayload extends IdempotencyHeaderType {
  data: { slippage: number };
}
